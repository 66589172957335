/* Provide sufficient contrast against white background */

.friendlist-box {
    text-decoration: none !important;
}

    .friendlist-box:hover {
        background-color: #e3e3e3;
    }

    .friendlist-box .feather {
        font-size: 32px;
        line-height: 48px;
    }

    .friendlist-box .photo-table.fa {
        font-size: 32px;
        line-height: 48px;
    }

    .friendlist-box .f-12 {
        line-height: 48px;
        font-size: 16px;
    }


.list-group-item:hover {
    border: none !important;
}

.active.list-group-item {
    border: none !important;
    z-index: 0 !important;
}

    .active.list-group-item h5 {
        color: #fff !important;
    }

    .active.list-group-item div {
        border-left: 10px solid #ffffff8a;
    }

.list-group-item {
    margin-right: 10px !important;
    background-color: #0000000a;
    border: none;
    text-align: center;
    margin-bottom: 10px !important;
}


a {
    color: #0366d6;
}

div.toConcierge {
    text-align: right;
}

.form-group.is-invalid .invalid-feedback {
    display: inline;
}

.chat-message {
    padding: 10px;
    background-color: #f3f3f3;
    width: 25%;
    min-width: 250px;
    border-radius: 10px;
    display: inline-block;
    text-align: left;
}

    .chat-message.toConcierge {
        border-top-right-radius: 0;
    }

    .chat-message.toResident {
        background-color: #ffe6e6;
        border-top-left-radius: 0;
    }

.clickable {
    cursor: pointer;
}

.selected-div {
    border-left: solid 5px #a2a1a1;
    padding-left: 10px;
}

code {
    color: #E01A76;
}

.bold {
    font-weight: bold;
}

.i-block {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin: 5px;
    border: 1px solid #f1f1f1;
    position: relative;
    cursor: pointer;
}

    .i-block i {
        font-size: 30px;
    }

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.5) !important;
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

button.btn-outline-dark:hover {
    background-color: black !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.table-linked td a {
    display: block;
    padding: 0.75rem;
    color: #212529;
}

.table-linked td {
    padding: 0 !important;
}

.table-linked tr:hover {
    background-color: #f1f1f1;
}

.table-linked td a:hover {
    text-decoration: none;
}

.error-text {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

table tr td {vertical-align: middle;}

.navbar{
    padding-left: 10px;
}

label{
    margin-top: 10px;
}

.form-group{
    margin-bottom: 10px;
}

.form-group input{
    background: white !important;
}